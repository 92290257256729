<template>
    <div id="about">
        <div class="content">
            <h2>О нас</h2>
            <p>
                {{ companyName }} - команда профессионалов с огромным опытом автоматизации и цифровизации ритейла. Наши решения всегда
                актуальны, стабильны и отказоустойчивы. Цель команды - качественное и эффективное решение бизнес задач
            </p>
            <div>
                <h2>Наша команда</h2>

                <div class="team">

                    <section>
                        <h3>Разработчики 1С</h3>
                        <ul>
                            <li>Разработка решений 1С с нуля (включая мобильные приложения)</li>
                            <li>Адаптация и оптимизация существующих решений 1С</li>
                            <li>Доработка типовых решений 1С</li>
                            <li>Интеграция решений 1С с другими информационными системами (ЭДО, ЕГАИС, Меркурий, Честный знак и т.д.)</li>
                            <li>Разработка целевых подсистем и сервисов</li>
                            <li>Интеграция с торговым оборудованием</li>
                        </ul>
                    </section>

                    <section>
                        <h3>WEB разработчики</h3>
                        <ul>
                            <li>Внедрение и адаптация решений Bitrix24</li>
                            <li>Разработка сайтов</li>
                            <li>e-commerce решения</li>
                        </ul>
                    </section>

                </div>

                <div class="team">

                    <section>
                        <h3>Java разработчики</h3>
                        <ul>
                            <li>Собственное кассовое программное обеспечение (Стационарные кассы, кассы самообслуживания, мобильный ассистент КСО)</li>
                            <li>Интеграция с различным торговым оборудованием</li>
                            <li>Реализация высоконагруженных сервисов</li>
                        </ul>
                    </section>

                    <section>
                        <h3>BI разработчики</h3>
                        <ul>
                            <li>Построение хранилищ данных (DWH)</li>
                            <li>Разработка ETL процессов</li>
                            <li>Создание эффективных дашбордов</li>
                        </ul>
                    </section>

                </div>
            </div>
            <section class="activities">
                <h2>Информация о видах деятельности</h2>
                <ul>
                    <li>62.01 Разработка компьютерного программного обеспечения</li>
                    <li>62.02 Деятельность консультативная и работы в области компьютерных технологий</li>
                    <li>62.03.13 Деятельность по сопровождению компьютерных систем</li>
                    <li>62.09 Деятельность, связанная с использованием вычислительной техники и информационных технологий, прочая</li>
                    <li>63.11.1 Деятельность по созданию и использованию баз данных и информационных ресурсов</li>
                </ul>
            </section>
            <section class="registration">
                <ul>
                    <li>Номер в реестре аккредитованных организаций: <span>32118</span></li>
                    <li>Номер решения о государственной аккредитации: <span>АО-20221107-9826253835-3</span></li>
                    <li>Дата решения о государственной аккредитации: <span>28.11.2022</span></li>
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutComponent",
    props: {
        companyName: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="sass" scoped>
#about
    text-align: center
    padding: 1rem 1rem 2rem

    p
        text-align: justify
        width: 80%
        margin: 1rem auto
        text-indent: 2rem
        font-size: .8rem
        line-height: 1rem

    .team
        display: inline-flex
        margin: 0
        padding: 0 4rem
        text-align: left

        h3
            font-size: 1rem
            margin: .5rem

            &:before
                display: inline-block
                content: '\2713'
                border: $main_color 3px solid
                border-radius: 1rem
                margin: 5px
                width: 16px

        ul
            min-width: 18rem

            li
                margin: 0 .8rem 1rem
                vertical-align: top
                text-align: left
                font-size: 14px

                img
                    height: 15rem
                    width: 100%
                    box-shadow: 2px 2px 15px rgba(0, 0, 0, .5)

                span
                    color: #999
                    font-size: .8rem

    .activities
        padding: 0 4rem

        ul
            min-width: 18rem

        li
            vertical-align: top
            margin: 0 .8rem 1rem
            text-align: start
            font-size: 14px

    .registration
        padding: 0 4rem

        li
            font-size: 18px
            display: flex
            justify-content: space-between

            span
                font-weight: bold


+media-max(960px)
    #about
        .team
            flex-direction: column


        .activities
            flex-direction: column

        .registration

            li
                flex-direction: column

                span
                    padding: 5px 0 0 0

        ul
            padding-left: 1rem

+media-max(480px)
    #about
        .team
            padding: 0

        .activities
            padding: 0


        p
            text-indent: 0
</style>

<template>
    <div id="services">
        <section class="content">
            <h2>Услуги</h2>
            <ul>
                <li>
                    <img src="@/assets/images/icon_integration.svg"/>
                    <h3>Программная интеграция</h3>
                    <!--          <span>Программное обеспечение, которое вам нужно</span>-->
                </li>
                <li>
                    <img src="@/assets/images/icon_development.svg"/>
                    <h3>Разработка</h3>
                    <!--          <span>Программное обеспечение, разработанное для возможностей</span>-->
                </li>
                <li>
                    <img src="@/assets/images/icon_optimization.svg"/>
                    <h3>Оптимизация</h3>
                    <!--          <span>Производительность, которая важнее всего</span>-->
                </li>
                <li>
                    <img src="@/assets/images/icon_database_store.svg"/>
                    <h3> Хранилища данных (DWH)</h3>
                    <!--          <span>Мудро реализованные возможности</span>-->
                </li>
                <li>
                    <img src="@/assets/images/icon_business_analysis.svg"/>
                    <h3>Бизнес-аналитика (BI)</h3>
                    <!--          <span>Лучшая скорость для бизнеса</span>-->
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
export default {
    name: "ServiceComponent"
}
</script>

<style lang="sass" scoped>
#services
    background: #000 url(@/assets/images/services_bg.jpg)
    color: #fff
    padding: 2rem 0

    ul
        padding: 0
        list-style: none
        text-align: justify

        &:after
            content: ''
            display: inline-block
            width: 100%

        li
            display: inline-block
            text-align: center
            vertical-align: middle
            width: $content / 5
            max-width: 100%

            img
                height: 5rem

            h3
                margin: .7rem 0 1rem

            span
                display: block
                color: #999
                font-size: .8rem

+media-max(1200px)
    #services
        padding: 0 3rem

        ul
            text-align: center

            li
                margin-bottom: 1rem

+media-max(480px)
    #services
        ul
            text-align: center

            li
                margin-bottom: 1rem
</style>

<template>
    <footer>
        <div>© 2022 {{ companyName }} - цифровая трансформация вашего бизнеса</div>
        <div>Все права защищены!</div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
    props: {
        companyName: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="sass" scoped>
footer
    background: #000 url(@/assets/images/services_bg.jpg)
    padding: 1rem
    color: #fff
    font-size: .8rem
    display: flex
    justify-content: space-between

+media-max(1000px)
    #header
        .nav
            display: none

        .contacts
            display: none

+media-max(480px)
    #header
        .logo
            height: 3rem
            width: 100%
    #footer
        .copyright
            float: none
            text-align: center

        .right
            float: none
            text-align: center
</style>

<template>
    <header id="header">
        <div class="content">
            <div class="logo">
                <img src="@/assets/images/it-level.png"/>
            </div>
            <nav>
                <ul>
                    <li v-for="tab in navTabs" :key="tab">
                        <span @click.prevent="this.$parent.scroll(tab.href)">{{ tab.name }}</span>
                    </li>
                </ul>
            </nav>
            <div class="contacts">
                <span><a v-bind:href="`mailto:${email}`">{{ email }}</a></span>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "HeaderComponent",
    props: {
        navTabs: {
            type: Array,
            required: true
        },
        email: {type: String}
    },
}
</script>

<style lang="sass" scoped>
header
    width: 100%
    margin: 0 auto
    position: absolute
    top: 0
    left: 0
    z-index: 1
    padding: 1rem

    a, span
        color: #fff
        text-decoration: none
        cursor: pointer
        position: relative

        &:before
            content: ''
            position: absolute
            bottom: -.5rem
            left: 50%
            background-color: $main_color
            height: 3px
            width: 0
            transition: 1s

        &:hover
            color: $main_color

            &:before
                left: 10%
                width: 80%

    .content
        text-align: justify

        &:after
            content: ''
            display: inline-block
            width: 100%

    .logo
        display: inline-block
        vertical-align: middle
        height: 6rem

        img
            height: 100%

    nav
        position: relative
        display: inline-block
        vertical-align: middle
        list-style: none
        margin: 0
        padding: 0
        left: 15%

        ul
            padding: 0

        li
            display: inline-block
            padding: 0 5px
            margin: 0 1rem

    .contacts
        position: relative
        left: 20%
        display: inline-block
        vertical-align: middle

        span
            display: block

+media-max(980px)
    header
        .content
            nav
                left: 0

            .contacts
                left: 0

            .logo
                height: 4rem

+media-max(830px)
    header
        .content
            .contacts
                display: none

+media-max(570px)
    #header
        .content
            nav
                li
                    font-size: 4vw
                    margin: 0 5px

+media-max(360px)
    header
        .content
            nav
                li
                    padding: 0
</style>

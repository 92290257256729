<template>
    <div id="feedback">
        <div class="content">
            <h2>Напишите нам</h2>
            <form method="post">
                <div class="form-control">
                    <label class="required">Имя:</label>
                    <input ref="name" v-model="feedback.name" type="text" name="name" placeholder="Иванов Иван"/>
                </div>
                <div class="form-control">
                    <label class="required">Email:</label>
                    <input ref="email" v-model="feedback.email" type="text" name="email" placeholder="example@site.com"/>
                </div>
                <div class="form-control">
                    <label>Телефон:</label>
                    <input ref="phone" v-model="feedback.phone" type="text" name="phone" placeholder="+7 (999) 999-99-99"/>
                </div>
                <div class="form-control">
                    <label class="required">Вопрос:</label>
                    <textarea ref="task" v-model="feedback.task" name="task" placeholder="Напишите свой вопрос"></textarea>
                </div>
                <input @click.prevent="sendEmail" class="button" type="submit" value="Отправить"/>
                <span>Обязательные поля</span>
                <div ref="message" class="message"></div>
            </form>
        </div>
    </div>
</template>

<script>

import axios from "axios";

export default {
    name: "FeedbackComponent",
    data() {
        return {
            feedback: {
                name: "",
                email: "",
                phone: "",
                task: ""
            },
        }
    },
    methods: {
        sendEmail() {
            let is_error = false;
            let re_email = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;

            if (!re_email.test(this.feedback.email)) {
                this.$refs.email.style.border = '1px solid #f00';
                is_error = true;
            } else this.$refs.email.style.border = 'none';

            if (this.feedback.name === "") {
                this.$refs.name.style.border = '1px solid #f00';
                is_error = true;
            } else this.$refs.name.style.border = 'none';

            if (this.feedback.task === "") {
                this.$refs.task.style.border = '1px solid #f00';
                is_error = true;
            } else this.$refs.task.style.border = 'none';

            if (is_error) {
                this.$refs.message.textContent = 'Не верно заполены обязательные поля';
                this.$refs.message.style.color = '#f00';
            } else {
                axios.post('http://it-lvl.ru/mail.php', {
                    name: this.feedback.name,
                    email: this.feedback.email,
                    phone: this.feedback.phone,
                    task: this.feedback.task
                }).then((r) => {
                    this.feedback.name = "";
                    this.feedback.email = "";
                    this.feedback.phone = "";
                    this.feedback.task = "";
                    if (r?.data?.result === "success") {
                        this.$refs.message.textContent = 'Сообщение отправлено';
                        this.$refs.message.style.color = '#0f0';
                    } else {
                        this.$refs.message.textContent = 'Сообщение не отправлено';
                        this.$refs.message.style.color = '#f00';
                    }
                }).catch(() => {
                    this.$refs.message.textContent = 'Сообщение не отправлено';
                    this.$refs.message.style.color = '#f00';
                })
            }
        }
    }
}
</script>

<style lang="sass" scoped>
#feedback
    background-color: #21252a
    color: #fff
    padding: 1rem

    form
        display: block
        width: $content / 2
        max-width: 100%
        margin: 0 auto
        padding: 1rem 0

        .form-control
            overflow: hidden
            margin: 1rem 0
            font-size: 0

            label
                display: inline-block
                width: 5rem
                max-width: 100%
                font-size: 1rem
                vertical-align: middle
                text-align: right
                margin-right: 1.5rem
                position: relative

                &.required:after
                    content: '*'
                    color: #f00
                    position: absolute
                    right: -.6rem

            input
                display: inline-block
                vertical-align: middle
                width: calc(100% - 6.5rem)
                padding: .3rem .5rem
                font-size: 1rem
                border: none

            textarea
                @extend input
                resize: vertical

        .button
            display: block
            margin: 0 auto
            font-size: 1rem
            cursor: pointer

        span
            display: block
            margin-top: 1rem
            font-size: .8rem
            color: #999
            text-align: center

            &:before
                content: '*'
                color: #f00
                margin-right: .3rem

        .message
            font-size: .8rem
            min-height: 1.2rem
            text-align: center

+media-max(480px)
    #feedback
        form
            .form-control
                label
                    width: 100%
                    text-align: left

                input
                    width: 100%
                    font-size: .8rem

                textarea
                    height: 3rem
</style>

<template>
    <div id="contacts">
        <div class="content">
            <div class="text">
                <h3>Наши контакты</h3>
                <ul>
                    <li>660064, Красноярский край, г. Красноярск, ул. Академика Вавилова, д. 1, стр. 51/1 офис 1-03</li>
                    <li>Режим работы: 8:00 - 17:00 (+4 МСК)</li>
                    <li><a href="tel:+7(391)296-53-95">+7(391)296-53-95</a></li>
                    <li><a v-bind:href="`mailto:${email}`">{{ email }}</a></li>
                </ul>
            </div>
        </div>
        <div id="map">
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A5dd24f1e3f96d58e5e4eec3dba957dfed83b4aba18ea991b7edc9a598322d8be&amp;source=constructor" height="100%" width="100%"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactsComponent",
    props: {
        email: {type: String}
    },
}
</script>

<style lang="sass" scoped>
#contacts
    position: relative
    height: 30rem

    .content
        overflow: visible

        .text
            width: $content / 3
            position: absolute
            background-color: #fff
            right: 0
            padding: 0 2rem 2rem
            z-index: 1
            margin-top: 5rem

            h3
                text-align: center

            ul
                margin: 0
                padding: 0
                font-size: .8rem

                li
                    margin: .5rem 0

                    a
                        color: #000
                        text-decoration: none

                        &:hover
                            color: $main_color

#map
    position: absolute
    height: 30rem
    width: 100%
    top: 0

+media-max(960px)
    #contacts
        height: auto

        .content
            .text
                width: 100%
                position: relative
                margin: 0 auto

                ul
                    font-size: .7rem
    #map
        position: relative
</style>

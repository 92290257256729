<template>
    <header-component :nav-tabs="navTabs" :email="email"></header-component>
    <main-component :company-name="companyName"></main-component>
    <service-component></service-component>
    <about-component :company-name="companyName"></about-component>
    <feedback-component></feedback-component>
    <contacts-component :email="email"></contacts-component>
    <pagination-component :nav-tabs="navTabs"></pagination-component>
    <FooterComponent :company-name="companyName"></FooterComponent>
</template>

<script>

import HeaderComponent from "@/components/HeaderComponent";
import MainComponent from "@/components/MainComponent";
import FooterComponent from "@/components/FooterComponent";
import ServiceComponent from "@/components/ServiceComponent";
import AboutComponent from "@/components/AboutComponent";
import FeedbackComponent from "@/components/FeedbackComponent";
import ContactsComponent from "@/components/ContactsComponent";
import PaginationComponent from "@/components/PaginationComponent";

export default {
    name: 'App',
    components: {
        PaginationComponent,
        ContactsComponent,
        FeedbackComponent,
        AboutComponent,
        ServiceComponent,
        FooterComponent,
        MainComponent,
        HeaderComponent
    },
    data() {
        return {
            navTabs: [
                {id: 1, href: 'main'},
                {id: 2, href: 'services', name: 'Услуги'},
                {id: 3, href: 'about', name: 'О нас'},
                {id: 4, href: 'feedback', name: 'Контакты'},
            ],
            email: 'info@it-lvl.ru',
            companyName: 'IT-Level'
        }
    },

    methods: {
        scroll(href) {
            document.getElementById(href).scrollIntoView({behavior: "smooth"});
        }
    }
}
</script>

<style lang="sass">
html
    font-family: Gilroy, sans-serif
    font-size: 20px
    height: 100%

body
    padding: 0
    margin: 0
    height: 100%

*
    box-sizing: border-box


*:focus
    outline: none

.content
    width: 100%
    max-width: $content
    position: relative
    margin: 0 auto
    overflow: hidden

.button
    background-color: $main_color
    padding: 1rem 2rem
    text-transform: uppercase
    border: none
    border-radius: .5rem
    margin-top: 1rem
    display: inline-block
    color: #fff
    text-decoration: none

    &:hover
        color: #fff
        box-shadow: 0 0 .5rem rgba(0, 0, 0, .7) inset

h2
    position: relative
    margin: 1rem 0 2rem
    text-align: center
    text-transform: uppercase
    font-size: 1.5rem

    &:after
        content: ''
        position: absolute
        left: 50%
        bottom: -.5rem
        width: 5rem
        border: 2px solid $main_color
        margin-left: -2.5rem
</style>

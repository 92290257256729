<template>
  <main id="main">
    <div class="wrapper">
      <div class="content">
        <h1>{{ companyName }} - цифровая трансформация вашего бизнеса</h1>
        <p>Конвертируем интересы заказчика в эффективные и надежные решения</p>
        <span @click.prevent="this.$parent.scroll('feedback')" class="button">Связаться с нами</span>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "MainComponent",
  props: {
    companyName: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
#main
  background: url(@/assets/images/it_level_background.jpg) no-repeat fixed center
  background-size: cover
  min-height: 100%
  position: relative

  &:before
    content: ''
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba(0, 0, 0, .6)

  .button
    margin: 0 auto
    font-size: 1rem
    cursor: pointer

  .wrapper
    color: #fff
    padding: 1rem

    .content
      width: $content * .85
      max-width: 100%
      margin: 9rem auto

      h1
        font-size: 3rem

      p
        line-height: 2rem

+media-max(1200px)
  #main
    .wrapper
      .content
        padding: 0 4rem

        h1
          font-size: 2rem

+media-max(480px)
  #main
    .wrapper
      .content
        padding: 0
        margin: 3rem auto

        h1
          font-size: 2rem

        p
          line-height: 1.5rem
          font-size: .9rem

+media-max(400px)
  #main
    .wrapper
      .content
        h1
          font-size: 1.8rem

        p
          line-height: 1.3rem
          font-size: .8rem

+media-max(350px)
  #main
    .wrapper
      .content
        h1
          font-size: 1.3rem

        p
          line-height: 1.2rem
          font-size: .7rem
</style>

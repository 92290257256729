<template>
    <div id="pagination">
        <ul>
            <li :class="tab.id === activeTab ? 'active' : ''" v-for="tab in navTabs" :key="tab">
                <span @click.prevent="this.$parent.scroll(tab.href)">{{ tab.id }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "PaginationComponent",
    props: {
        navTabs: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            activeTab: 1,
            tabs: []
        }
    },

    created() {
        window.addEventListener('scroll', this.setActiveMenu);

        this.navTabs.forEach((tab, i) => {
            let h = Math.round(document.getElementById(tab.href).offsetHeight * 0.55)

            if (this.tabs[0] === undefined) this.tabs.push(h);
            else this.tabs.push(h + this.tabs[i - 1])
        })
    },
    methods: {
        setActiveMenu() {
            this.tabs.forEach((val, i) => {
                if (window.top.scrollY > val) this.activeTab = i + 1;
            })
        }
    }
}
</script>

<style lang="sass" scoped>
#pagination
    position: fixed
    left: 2rem
    top: 50%
    margin-top: -6.5rem
    z-index: 6

    ul
        list-style: none
        margin: 0
        padding: 0

        li
            background-color: $main_color
            height: 1.5rem
            width: 1.5rem
            text-align: center
            margin: 1rem 0
            border-radius: 50%

            &.active
                box-shadow: 0 0 0 2px #fff, 0 0 0 5px $main_color,

            &:hover:not(.active)
                transform: scale(1.3)

            a, span
                color: #fff
                line-height: 1.5rem
                text-decoration: none
                display: block

+media-max(480px)
    #pagination
        display: none
</style>
